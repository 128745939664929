import { AccountInfo } from '@azure/msal-browser';
import { App } from './appTypes';


export type ApiUser = {
    ID: string;
    Surname: string;
    GivenName: string;
    DisplayName: string;
    Mail: string | null;
    ConfiguredApps: App[] | null;
};

export type User = {
    localId: string;
    imageURL?: string;
    name: string;
    initials: string;
    username: string;
} & ApiUser;

export type AnalyticsUser = {    
    access_token: string;
    client_token: string;
} & User;

export const MsalAccountToUser = (account?: AccountInfo) => {
    return {
        localId: account?.localAccountId,
        name: account?.name,
        username: account?.username,
        initials: getInitials(account?.name),
    } as User;
};

export const MsalAccountToAnalyticsUser = (account?: AccountInfo) => {
    return {
        localId: account?.localAccountId,
        name: account?.name,
        username: account?.username,
        initials: getInitials(account?.name),
    } as AnalyticsUser;
};

export const getInitials = (name?: string) =>
    name
        ?.toUpperCase()
        .split(' ')
        .map((n) => n.substring(0, 1))
        .join('');

export type UserServiceAccess = {
    AccessibleDatasets: any[];
}
