import { AnalyticsUser, User } from '../types';
import { useMsalAnalyticsUser, useMsalUser } from '../auth';
import { useEffect, useState } from 'react';
import { useGetAnalyticsUserQuery, useGetCurrentUserQuery } from '../services';

interface UserWithApiError extends User {
    isUserApiError?: boolean;
    isUserApiFetching?: boolean;
    UserAccessType?: string;
    imcUser?
    imcApiUser?
    isAnalyticsUser?: boolean;
}

interface AnalyticsUserWithApiError extends AnalyticsUser {
    isUserApiError?: boolean;
    isUserApiFetching?: boolean;
    UserAccessType?: string;
    imcUser?
    imcApiUser?
}

export function useUser(isAnalyticsUser?: boolean) {
    if (isAnalyticsUser) {
        const msalUser = useMsalAnalyticsUser();
        const [user, setAnalyticsUser] = useState<AnalyticsUserWithApiError>();
        const { data: analyticsUser, isError, isFetching } = useGetAnalyticsUserQuery(undefined, {
            skip: !msalUser?.localId,
        });

        useEffect(() => {
            setAnalyticsUser({ ...msalUser, ...analyticsUser, isUserApiError: isError, isUserApiFetching: isFetching });
        }, [msalUser, analyticsUser, isError, isFetching]);

        return user;
    }
    else {
        const msalUser = useMsalUser();
        const [user, setUser] = useState<UserWithApiError>();
        const { data: apiUser, isError, isFetching } = useGetCurrentUserQuery(undefined, {
            skip: !msalUser?.localId,
        });

        useEffect(() => {
            setUser({ ...msalUser, ...apiUser, isUserApiError: isError, isUserApiFetching: isFetching });
        }, [msalUser, apiUser, isError, isFetching]);

        return user;
    }
}

export function useIMCUser(imcUser) {
    const [user, setUser] = useState<UserWithApiError>();
    const { data: imcApiUser, isError: imcIsError, isFetching: imcIsFetching } = useGetCurrentUserQuery(imcUser, {
        skip: !imcUser?.localId,
    });

    useEffect(() => {
        if (
            imcUser !== user?.imcUser ||
            imcApiUser !== user?.imcApiUser ||
            imcIsError !== user?.isUserApiError ||
            imcIsFetching !== user?.isUserApiFetching
        ) {
            setUser(prevUser => ({
                ...prevUser,
                ...imcUser,
                ...imcApiUser,
                isUserApiError: imcIsError,
                isUserApiFetching: imcIsFetching,
            }));
        }
    }, [imcIsError, imcIsFetching]);
    return user;
}
