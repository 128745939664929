import { createElement } from '@syncfusion/ej2-base';
import { DataManager } from '@syncfusion/ej2-data';
import {
  Sort,
  Page,
  Freeze,
  Inject,
  Resize,
  Reorder,
  ColumnMenu,
  ColumnDirective,
  ColumnsDirective,
  TreeGridComponent,
  Filter,
  FilterSettingsModel,
  SortSettingsModel,
} from '@syncfusion/ej2-react-treegrid';
import {
  DisposalRequest,
  DisposalRequestStatus,
  Item,
  ItemGridQuery,
} from '../../types';
import { Box } from '../Box';
import DetailDialog from '../dialog/DetailDialog';
import {
  ItemSummaryTemplate,
  MemoizedCommentTemplate,
  MemoizedPagerTemplate,
  MemoizedRetentionClassTemplate,
  MemoizedSummaryTemplate,
  PagerData,
} from './templates';
import { ColumnChooser } from '@syncfusion/ej2-react-treegrid';
import { forwardRef, RefObject, useEffect, useMemo } from 'react';
import {
  OpenInContextMenu,
  OpenInContextMenuTarget,
} from './components/OpenInContextMenu';
import useItemsGrid from './useItemsGrid';
import { MemoizedDisposalStatusTemplate } from './templates/DisposalStatusTemplate';
import { MemoizedBusinessTypeTemplate } from './templates/BusinesssTypeTemplate';
import { MemoizedItemManagementStatusChip } from '../chip';
import { Typography } from '../Typography';
import { formatDestructionDate } from '../../util';
import { MemoizedDestructionFailureTemplate } from './templates/DestructionFailureTemplate';
import { DropDownList } from '@syncfusion/ej2-react-dropdowns';
import { ItemLevel } from '../../store/slices/itemModalSlice';

export type ItemsGridProps = {
  key?: string;
  data?: Item[];
  testId?: string;
  filter?: string;
  pageSize?: number;
  disposalId?: string;
  adminMode?: boolean;
  pageSizes?: number[];
  isItemPanel?: boolean;
  persistanceId?: string;
  selectedRecords?: any[];
  itemGridType: ItemGridQuery;
  selectedRecordsForRemoval?: any[];
  disposalRequest?: DisposalRequest;
  onOpenURL?: (url: string) => void;
  onSelect?: (request: any) => void;
  onOpen?: (item: Item, target?: OpenInContextMenuTarget) => void;
  onOpenItems?: (item: Item, target?: OpenInContextMenuTarget) => void;
  onOpenDisposalRequest?: (request: DisposalRequest) => void;
  onOpenDisposalRequestContextMenu?: (
    e: React.MouseEvent,
    request: DisposalRequest
  ) => void;
  isItemCollection?: boolean;
  levels?: ItemLevel[];
};

export const ItemsGrid = forwardRef(
  (
    {
      data,
      filter,
      disposalId,
      itemGridType,
      persistanceId,
      pageSize = 30,
      disposalRequest,
      selectedRecords,
      adminMode = false,
      isItemPanel = false,
      testId = 'ItemsGrid',
      pageSizes = [30, 60, 100],
      selectedRecordsForRemoval,
      onOpen,
      onSelect,
      onOpenURL,
      onOpenItems,
      onOpenDisposalRequest,
      onOpenDisposalRequestContextMenu,
      isItemCollection = false,
      levels = [],
    }: ItemsGridProps,
    gridRef: RefObject<TreeGridComponent>
  ) => {
    const showCheckbox =
      (disposalRequest?.Status === DisposalRequestStatus.New ||
        disposalRequest?.Status === DisposalRequestStatus.Rejected) &&
      !isItemPanel; // remove the checkbox if the items grid is from the item panel.

    let stateValue;
    const dateFormat = { type: 'date', format: 'dd MMMM yyy' };

    const filterSettings: FilterSettingsModel = {
      type: 'Menu',
    };

    const initialSortValue = levels[levels.length - 1]?.sortSettings
      ? [levels[levels.length - 1]?.sortSettings]
      : [];

    const initialPageSize = levels[levels.length - 1]?.pageSize
      ? levels[levels.length - 1]?.pageSize
      : 30;

    const sortingOptions: SortSettingsModel = {
      columns: initialSortValue,
    };

    const {
      key,
      comment,
      setComment,
      rowSelected,
      rowDeselected,
      getDataSource,
      dataStateChange,
      contextAnchor,
      setContextAnchor,
      contextItem,
      setContextItem,
      failureDetailsLookup,
    } = useItemsGrid({
      onSelect,
      pageSize: initialPageSize,
      disposalId,
      data,
      adminMode,
      filter,
      itemGridType,
      selectedRecords,
      selectedRecordsForRemoval,
      gridRef,
      stateValue,
      showCheckbox,
      isItemPanel,
      initialSortValue,
      levels,
    });

    const handleOpenInContextMenu = (target) => {
      setContextAnchor(null);
      onOpen && contextItem && onOpen(contextItem, target);
    };

    const handleCloseInContextMenu = () => setContextAnchor(null);

    let dropInstance;
    const FilterType = {
      ui: {
        create: (args) => {
          const flValInput = createElement('input', { className: 'flm-input' });
          args.target.appendChild(flValInput);
          dropInstance = new DropDownList({
            dataSource: new DataManager(failureDetailsLookup),
            fields: { text: 'Caption', value: 'Name' },
            placeholder: 'Select a value',
            popupWidth: 'auto',
          });
          dropInstance.appendTo(flValInput);
        },
        read: (args) => {
          args.fltrObj.filterByColumn(
            args.column.field,
            args.operator,
            dropInstance.value
          );
        },
        write: (args) => {
          dropInstance.value = args.filteredValue;
        },
      },
    };

    const actionComplete = (props: any) => {
      // For some reason sort is lost after changing pages
      if (props?.requestType === 'sorting') {
        stateValue = {
          ...stateValue,
          sortSettings: {
            direction: props.direction,
            field: props.columnName,
          },
        };
      }

      if (props?.requestType === 'paging') {
        stateValue = {
          currentPage: props.currentPage,
          pageSize: props.pageSize,
        };
      }
    };

    useEffect(() => {
      if (stateValue) stateValue = undefined;
    }, [levels]);

    const TreeGrid = () => {
      const gridId =
        isItemPanel && levels?.length
          ? levels[levels?.length - 1]?.id
          : disposalId;
      const newPage = levels[levels.length - 1]?.pageIndex
        ? levels[levels.length - 1]?.pageIndex
        : 1;
      const newPageSize = levels[levels.length - 1]?.pageSize
        ? levels[levels.length - 1]?.pageSize
        : pageSize;
      const selectedRow =
        levels[levels.length - 1]?.rowIndex >= 0
          ? levels[levels.length - 1]?.rowIndex
          : -1;

      return (
        <TreeGridComponent
          // enableCollapseAll //TODO: Add when treegrid nesting is required
          allowReordering={true}
          dataSource={!!data ? data : []}
          dataBound={!!data ? undefined : getDataSource}
          dataStateChange={!!data ? undefined : dataStateChange}
          loadingIndicator={{ indicatorType: 'Shimmer' }}
          ref={gridRef}
          showColumnChooser={true}
          treeColumnIndex={showCheckbox ? 1 : 0}
          allowResizing={true}
          actionComplete={actionComplete}
          parentIdMapping='ParentId'
          allowSorting={true}
          data-testid={testId}
          hasChildMapping='HasMember'
          className='items-grid-tree-grid'
          idMapping='ID'
          height='100%'
          width='100%'
          childMapping={!!data ? 'Items' : undefined}
          //need the id to maintain persisted data
          id={`${
            !!persistanceId ? persistanceId + itemGridType : 'ItemGridl10'
          }-${gridId}`}
          allowPaging={true}
          allowFiltering={true}
          pageSettings={{
            pageSize: stateValue?.pageSize ?? newPageSize,
            currentPage: newPage,
          }}
          selectedRowIndex={selectedRow}
          pagerTemplate={(pagerData: PagerData) => {
            const pagerDataWithTotalPages = {
              ...pagerData,
              pageSize: pagerData.pageSize,
              totalPages: Math.ceil(
                pagerData.totalRecordsCount! / pagerData.pageSize!
              ),
            };

            return (
              <MemoizedPagerTemplate
                key={key}
                pagerData={pagerDataWithTotalPages}
                grid={gridRef?.current}
                pageSizes={pageSizes}
              />
            );
          }}
          filterSettings={filterSettings}
          sortSettings={sortingOptions}
          rowSelected={rowSelected}
          rowDeselected={rowDeselected}
        >
          <ColumnsDirective>
            {/* TODO: Add Checkbox for future functionality */}
            <ColumnDirective
              type='checkbox'
              width='40'
              showInColumnChooser={false}
              field='Checkbox'
              allowFiltering={false}
            />
            <ColumnDirective
              field='DisplayName'
              key='DisplayName'
              headerText={
                itemGridType === ItemGridQuery.DestroyItems
                  ? 'Item Summary'
                  : 'Item'
              }
              clipMode='Ellipsis'
              showCheckbox={false}
              allowResizing={true}
              showInColumnChooser={false}
              width={'5rem'}
              template={(props) => {
                const item = props;
                return (
                  <ItemSummaryTemplate
                    adminMode={adminMode}
                    title={item.DisplayName}
                    itemId={item.ID}
                    disposalRequest={disposalRequest}
                    filter={filter}
                    imageId={!!item._ImageId ? item._ImageId : undefined}
                    itemGridType={itemGridType}
                    type={
                      item.AttachedItems?.value?.length ? 'attachment' : 'item'
                    }
                    count={
                      !!item.Members
                        ? item.Members['@odata.count']
                        : item.AttachedItems?.value.length ?? 0
                    }
                    tags={[
                      item.BusinessType?.Caption.toUpperCase() ?? '',
                      item.TypeDef?.Caption.toUpperCase() ?? '',
                    ].filter((x) => x != '')}
                    onClick={() => {
                      if (isItemCollection) {
                        const initialValue = stateValue
                          ? stateValue?.currentPage
                          : levels[levels.length - 1]?.pageIndex;
                        const initialPageSize = stateValue
                          ? stateValue?.pageSize
                          : levels[levels.length - 1]?.pageSize;
                        const initialSort = stateValue
                          ? stateValue?.sortSettings
                          : levels[levels.length - 1]?.sortSettings;
                        const itemMember = {
                          ...item,
                          page: initialValue ?? 1,
                          pageSize: initialPageSize ?? 30,
                          sortSettings: initialSort ?? {},
                        };
                        onOpen && onOpen(itemMember, 'modal');
                      } else onOpen && onOpen(item, 'modal');
                    }}
                    onClickItems={() => {
                      const initialValue = stateValue
                        ? stateValue?.currentPage
                        : levels[levels.length - 1]?.pageIndex;
                      const initialPageSize = stateValue
                        ? stateValue?.pageSize
                        : levels[levels.length - 1]?.pageSize;
                      const initialSort = stateValue
                        ? stateValue?.sortSettings
                        : levels[levels.length - 1]?.sortSettings;
                      const itemMember = {
                        ...item,
                        page: initialValue ?? 1,
                        pageSize: initialPageSize ?? 30,
                        sortSettings: initialSort ?? {},
                      };
                      onOpenItems && onOpenItems(itemMember, 'modal');
                    }}
                    onContextMenu={(e: React.MouseEvent) => {
                      setContextItem(item);
                      setContextAnchor(e.currentTarget);
                    }}
                  />
                );
              }}
              minWidth={'18rem'}
              allowFiltering={false}
            />
            <ColumnDirective
              field='StorageObject.FileExtension'
              key='StorageObject.FileExtension'
              headerText='Extension'
              allowFiltering={false}
            />
            <ColumnDirective
              field='CreatedBy.DisplayName'
              key='CreatedBy.DisplayName'
              headerText='Created By'
              allowFiltering={false}
            />
            <ColumnDirective
              field='DateCreated'
              key='DateCreated'
              headerText='Date Created'
              type='date'
              format={dateFormat}
              allowFiltering={false}
            />
            <ColumnDirective
              field='DateModified'
              key='DateModified'
              headerText='Date Modified'
              type='date'
              format={dateFormat}
              allowFiltering={false}
            />
            <ColumnDirective
              field='DisposalRequests.Name'
              key='DisposalRequests'
              headerText='Disposal Requests'
              allowFiltering={false}
              showInColumnChooser={
                itemGridType === ItemGridQuery.DestroyItems &&
                filter === DisposalRequestStatus.Failed
              }
              template={(item: Item) => {
                const _disposalDetailsDisposalRequests =
                  item?.DisposalDetails?.value.map((disposalDetail) => {
                    const _disposalRequest = disposalDetail.DisposalRequest;

                    return _disposalRequest
                      ? ({
                          ID: _disposalRequest.ID,
                          Name: _disposalRequest.Name,
                          RequestedBy: '',
                          ItemCount: 0,
                          IsRead: false,
                        } as unknown as DisposalRequest)
                      : ({
                          ID: null,
                          Name: '',
                          RequestedBy: '',
                          ItemCount: 0,
                          IsRead: false,
                        } as unknown as DisposalRequest);
                  });
                const _disposalRequests = _disposalDetailsDisposalRequests;

                return (
                  <Box
                    background='none'
                    style={{
                      marginTop:
                        _disposalRequests?.length > 1 ? '0.625rem' : '0',
                    }}
                  >
                    {_disposalRequests?.map((request: DisposalRequest) => {
                      const _subTitle = request.ItemCount
                        ? `Destroy ${request.ItemCount ?? ''} Items`
                        : undefined;
                      const _information =
                        request.RequestedBy &&
                        `Requested by ${request.RequestedBy?.DisplayName}`;

                      return (
                        <Box
                          background='none'
                          style={{ minHeight: '3.125rem' }}
                        >
                          <MemoizedSummaryTemplate
                            title={request.Name}
                            disposalId={request.ID}
                            subTitle={_subTitle}
                            information={_information}
                            isUnread={!request.IsRead}
                            onClick={() =>
                              !!onOpenDisposalRequest &&
                              onOpenDisposalRequest(request)
                            }
                            onContextMenu={(e: React.MouseEvent) =>
                              !!onOpenDisposalRequestContextMenu &&
                              onOpenDisposalRequestContextMenu(e, request)
                            }
                          />
                        </Box>
                      );
                    })}
                  </Box>
                );
              }}
            />
            <ColumnDirective
              field='DisposalStates'
              key='DisposalStates'
              headerText='Disposal Status'
              allowFiltering={false}
              template={(item: Item) => (
                <MemoizedDisposalStatusTemplate
                  item={item}
                  disposalRequest={disposalRequest}
                />
              )}
            />
            <ColumnDirective
              field='Status'
              key='Status'
              headerText={
                itemGridType === ItemGridQuery.DestroyItems
                  ? 'Item Status'
                  : 'Status'
              }
              allowFiltering={false}
              template={(item: Item) => {
                return (
                  <MemoizedItemManagementStatusChip
                    status={item.Status}
                    border={itemGridType !== ItemGridQuery.DestroyItems}
                  />
                );
              }}
            />
            <ColumnDirective
              field='DisposalDetails.CompletedDate'
              key='DisposalDetails'
              headerText='Date Destroyed'
              template={(item: Item) => {
                const formattedData = item?.DisposalDetails?.value?.[0]
                  ?.CompletedDate
                  ? formatDestructionDate(
                      item?.DisposalDetails?.value?.[0]?.CompletedDate
                    )
                  : null;

                return <Typography variant='body1'>{formattedData}</Typography>;
              }}
              allowFiltering={false}
            />
            <ColumnDirective
              field='Classifications'
              key='Classifications'
              headerText='Retention Classes'
              clipMode='Ellipsis'
              template={(item: Item) => (
                <MemoizedRetentionClassTemplate
                  classifications={item.Classifications?.value ?? []}
                />
              )}
              allowFiltering={false}
            />
            <ColumnDirective
              field='BusinessType'
              key='BusinessType'
              headerText='Business Type'
              template={(item: Item) => {
                return (
                  (item.BusinessType?.Caption ?? '') !== '' && (
                    <MemoizedBusinessTypeTemplate
                      title={item.BusinessType?.Caption ?? ''}
                      imageId={
                        !!item.BusinessType
                          ? item.BusinessType._ImageId
                          : undefined
                      }
                      information={
                        !!item?.BusinessType?.DerivedPath
                          ? item.BusinessType.DerivedPath
                          : undefined
                      }
                    />
                  )
                );
              }}
              allowFiltering={false}
            />
            <ColumnDirective
              field='DisposalDetails.FailureDetail'
              key='DisposalDetails'
              filter={FilterType}
              headerText='Destruction Failure'
              showInColumnChooser={
                itemGridType === ItemGridQuery.DestroyItems &&
                filter === DisposalRequestStatus.Failed
              }
              template={(item: Item) => (
                <MemoizedDestructionFailureTemplate item={item} />
              )}
              allowFiltering={true}
              allowSorting={true}
              type='string'
            />
            <ColumnDirective
              field='RepositoryURL'
              key='RepositoryURL'
              headerText='Repository URL'
              template={(item: Item) => (
                <MemoizedCommentTemplate
                  comment={item.RepositoryUrl}
                  isURL={true}
                  onClick={onOpenURL}
                  numberOfLines={3}
                  //open context menu - future use
                />
              )}
              allowFiltering={false}
            />
          </ColumnsDirective>
          <Inject
            services={[
              Resize,
              Reorder,
              Sort,
              Freeze,
              Page,
              ColumnMenu,
              Filter,
              ColumnChooser,
            ]}
          />
        </TreeGridComponent>
      );
    };

    // Need to implement a useMemo here to fix the rendering issue.
    const MemoizedTreeGridComponent = useMemo(
      () => <TreeGrid />,
      [filter, data, failureDetailsLookup, JSON.stringify(levels)]
    );

    return (
      //wrap with a box so that scrolling in the grid works
      <Box background='none'>
        {/* itemPanel TreeGrids are frequently updated so memoizing it doesn't update it correctly */}
        {isItemPanel ? <TreeGrid /> : MemoizedTreeGridComponent}
        <DetailDialog
          title='Comment'
          open={!!comment}
          onClose={() => setComment(null)}
        >
          {comment as string}
        </DetailDialog>
        <OpenInContextMenu
          anchor={contextAnchor!}
          options={['new tab', 'new window']}
          onOpen={handleOpenInContextMenu}
          onClose={handleCloseInContextMenu}
        />
      </Box>
    );
  }
);
export default ItemsGrid;
