import * as React from 'react';
import { SVGProps } from 'react';
const SvgAnalyticsAdmin = (props: SVGProps<SVGSVGElement>) => (
    <svg
        viewBox='0 0 56 64'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path d="M50 28.7574L37.8055 28.7782C36.9002 24.5018 33.5707 21.1618 29.2943 20.2462C29.2734 20.2462 29.3775 8.06201 29.3775 8.06201C40.1986 9.14412 48.9387 17.8218 50 28.7574Z" fill="#50B7EA" />
        <path d="M24.883 41.8364V53.9998C24.8517 53.9998 24.8205 53.9998 24.7789 53.9998C20.2216 53.5627 15.8931 51.7731 12.3555 48.8597L20.9395 40.2549C22.1153 41.0144 23.4159 41.5451 24.7789 41.826C24.7997 41.826 24.7789 41.8364 24.883 41.8364Z" fill="#DCE1EF" />
        <path d="M24.7891 8L24.7682 14.0869L19.7427 9.0613C21.3658 8.52025 23.041 8.16648 24.737 8.0104C24.7474 8.0104 24.7682 8.0104 24.7891 8Z" fill="#DCE1EF" />
        <path d="M24.7368 19.9234V20.1731C23.3113 20.4644 21.9691 21.0471 20.7725 21.8691L20.7621 21.8483L12.1885 13.285C13.2706 12.3798 14.4359 11.5682 15.6637 10.8711L24.716 19.9234H24.7368Z" fill="#DCE1EF" />
        <path d="M17.818 24.8043L17.8388 24.8147C17.048 25.9696 16.4966 27.2598 16.1948 28.6229H15.7162V28.5812L6.84082 19.7059C7.53795 18.4781 8.33913 17.3127 9.24435 16.2202L17.818 24.8043Z" fill="#DCE1EF" />
        <path d="M17.7553 37.0609L9.15042 45.6658C6.23705 42.1385 4.45782 37.8101 4.0104 33.2631C4.0104 33.2423 4.0104 33.3047 4 33.2007H16.1633C16.4547 34.5741 16.9957 35.8851 17.7553 37.0609Z" fill="#DCE1EF" />
        <path d="M9.81603 28.5805V28.6326H4.02051C4.18699 26.9886 4.53035 25.3758 5.04019 23.8047L9.81603 28.5805Z" fill="#DCE1EF" />
        <path d="M43.0136 34C43.9545 34.0104 44.8916 34.1148 45.8099 34.3114C46.2108 34.3973 46.5106 34.7182 46.5561 35.1101L46.7742 36.9893C46.873 37.8521 47.6317 38.5057 48.5362 38.5066C48.7793 38.5069 49.0198 38.4583 49.2445 38.3629L51.0398 37.6056C51.4132 37.4481 51.8494 37.534 52.1282 37.8198C53.4256 39.1503 54.3918 40.746 54.9542 42.4869C55.0755 42.8623 54.9359 43.2702 54.6064 43.5034L53.0151 44.6297C52.5612 44.9499 52.2931 45.4585 52.2931 45.9994C52.2931 46.5402 52.5612 47.0488 53.0161 47.3697L54.6088 48.4964C54.9384 48.7296 55.0781 49.1376 54.9568 49.513C54.3946 51.2536 53.4289 52.8491 52.1323 54.1799C51.8538 54.4657 51.4178 54.5518 51.0445 54.3947L49.2419 53.6364C48.7262 53.4197 48.1339 53.4514 47.6465 53.7219C47.1591 53.9923 46.8348 54.4692 46.7729 55.0065L46.5561 56.8855C46.5115 57.2729 46.2181 57.5917 45.8231 57.6819C43.9665 58.106 42.0322 58.106 40.1756 57.6819C39.7806 57.5917 39.4873 57.2729 39.4426 56.8855L39.2262 55.0093C39.1626 54.473 38.8378 53.9976 38.3508 53.728C37.8638 53.4585 37.2725 53.4269 36.7585 53.6424L34.9555 54.4009C34.5821 54.558 34.146 54.4718 33.8675 54.1858C32.5702 52.8536 31.6044 51.2561 31.043 49.5136C30.9221 49.1383 31.0618 48.7307 31.3912 48.4976L32.9849 47.3703C33.4388 47.05 33.7069 46.5415 33.7069 46.0006C33.7069 45.4597 33.4388 44.9512 32.9843 44.6305L31.3916 43.5051C31.0617 43.272 30.9219 42.8638 31.0432 42.4881C31.6056 40.7473 32.5719 39.1516 33.8693 37.8211C34.148 37.5352 34.5843 37.4494 34.9577 37.6069L36.7527 38.364C37.2691 38.5816 37.8629 38.5487 38.3525 38.2741C38.84 38.0026 39.1646 37.5252 39.2272 36.9879L39.4452 35.1101C39.4907 34.718 39.7908 34.397 40.1919 34.3113C41.1113 34.115 42.0495 34.0107 43.0136 34ZM42.9977 42.3077C40.8739 42.3077 39.1523 43.9608 39.1523 46C39.1523 48.0392 40.8739 49.6923 42.9977 49.6923C45.1214 49.6923 46.843 48.0392 46.843 46C46.843 43.9608 45.1214 42.3077 42.9977 42.3077Z" fill="#9EA7BF" />
        <defs>
            <linearGradient id="paint0_linear_2801_6139" x1="31.5557" y1="22.3067" x2="69.5604" y2="45.54" gradientUnits="userSpaceOnUse">
                <stop stop-color="#5600D7" />
                <stop offset="1" stop-color="#00EFEA" stop-opacity="0.66" />
            </linearGradient>
        </defs>
    </svg>
);
export default SvgAnalyticsAdmin;
