import * as React from 'react';
import { SVGProps } from 'react';
const SvgAnalytics = (props: SVGProps<SVGSVGElement>) => (
    <svg
        viewBox='0 0 56 64'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path d="M42 32.5C48.3472 32.5 53.5 37.6435 53.5 44C53.5 50.3567 48.3567 55.5 42 55.5C35.6433 55.5 30.5 50.3567 30.5 44C30.5 37.6531 35.6531 32.5 42 32.5Z" fill="#FAFAFC" stroke="url(#paint0_linear_2801_6139)" />
        <path d="M50 28.7575L37.8055 28.7783C36.9002 24.5019 33.5707 21.1619 29.2943 20.2463C29.2734 20.2463 29.3775 8.06213 29.3775 8.06213C40.1986 9.14424 48.9387 17.8219 50 28.7575Z" fill="#50B7EA" />
        <path d="M24.8827 41.8367V54C24.8515 54 24.8203 54 24.7787 54C20.2213 53.563 15.8929 51.7734 12.3552 48.86L20.9393 40.2551C22.115 41.0147 23.4156 41.5453 24.7787 41.8263C24.7995 41.8263 24.7787 41.8367 24.8827 41.8367Z" fill="#DCE1EF" />
        <path d="M24.7888 8L24.768 14.0869L19.7424 9.0613C21.3656 8.52025 23.0408 8.16648 24.7368 8.0104C24.7472 8.0104 24.768 8.0104 24.7888 8Z" fill="#DCE1EF" />
        <path d="M24.7368 19.9236V20.1733C23.3113 20.4647 21.9691 21.0473 20.7725 21.8693L20.7621 21.8485L12.1885 13.2853C13.2706 12.38 14.4359 11.5685 15.6637 10.8713L24.716 19.9236H24.7368Z" fill="#DCE1EF" />
        <path d="M17.8177 24.8042L17.8386 24.8146C17.0478 25.9695 16.4963 27.2597 16.1946 28.6228H15.716V28.5811L6.84058 19.7058C7.5377 18.478 8.33888 17.3126 9.24411 16.2201L17.8177 24.8042Z" fill="#DCE1EF" />
        <path d="M17.7553 37.0608L9.15042 45.6656C6.23705 42.1384 4.45782 37.8099 4.0104 33.263C4.0104 33.2422 4.0104 33.3046 4 33.2006H16.1633C16.4547 34.574 16.9957 35.885 17.7553 37.0608Z" fill="#DCE1EF" />
        <path d="M9.81628 28.5807V28.6327H4.02075C4.18723 26.9887 4.53059 25.376 5.04043 23.8048L9.81628 28.5807Z" fill="#DCE1EF" />
        <path d="M47 41.0571C46.9928 43.0571 45.8473 44.8732 44.0677 45.7041V47.9541H39.9323V45.6894C38.3401 44.9688 37.2378 43.4394 37.036 41.6747C37.0144 41.4688 37 41.2629 37 41.0571C37 38.2409 39.2406 35.9541 42 35.9541C44.7594 35.9468 47 38.2335 47 41.0571Z" fill="#DCE1EF" />
        <path d="M43.287 52H40.713C40.3209 52 40 51.325 40 50.5C40 49.675 40.3209 49 40.713 49H43.287C43.6791 49 44 49.675 44 50.5C44 51.325 43.6863 52 43.287 52Z" fill="#9EA7BF" />
        <defs>
            <linearGradient id="paint0_linear_2802_6440" x1="31.5557" y1="22.3067" x2="69.5604" y2="45.54" gradientUnits="userSpaceOnUse">
                <stop stop-color="#5600D7" />
                <stop offset="1" stop-color="#00EFEA" stop-opacity="0.66" />
            </linearGradient>
        </defs>
    </svg>

);
export default SvgAnalytics;
