export const propGroupsMapping = {};
export const displayPropsMapping = {};
export const editablePropsMapping = {};

export const createTree = (flatArray) => {
  const rootItems = [];

  // Create a mapping of propertyId to its object
  const idToItem = {};
  flatArray.forEach((item) => {
    idToItem[item.propertyID] = item;
  });

  // Iterate through the data to organize it
  flatArray.forEach((item) => {
    if (item.pId) {
      const parentItem = idToItem[item.pId];
      if (!parentItem.Items) {
        parentItem.Items = [];
      }
      parentItem.Items.push(item);
    } else {
      rootItems.push(item);
    }
  });

  return rootItems;
};

/// Transforms the given object's properties to syncfusion display properties
export const transformPropertiesOfObject = (
  displayProperties,
  object,
  parentId
) => {
  const editableProps = editablePropsMapping[object.TypeDefId];

  editableProps?.forEach((propDef) => {
    let dataPoints = rollupDataPoints(object, propDef, false);

    if (propDef.IsCollection) {
      let childObjects = [];
      const childCollection = object[propDef.Name];

      if ( childCollection?.value?.length > 0)
        childObjects = childCollection.value;

      // Create a row for the children to hang off
      const isImportant = checkIsImportant(propDef.Name, object.TypeDefId);
      const propertyDisplay = `${propDef.Caption} (${childObjects.length})`;
      const row = createDisplayProperty(
        displayProperties.length + 1,
        parentId,
        propertyDisplay,
        '',
        true,
        isImportant,
        'collection',
        propDef,
        null,
        dataPoints
      );

      displayProperties.push(row);

      if (childObjects?.length > 0) {
        childObjects.forEach((childObject) => {
          dataPoints = rollupDataPoints(childObject, propDef, true);
          transformPropertyIntoRow(
            displayProperties,
            childObject,
            propDef,
            object.TypeDefId,
            row['propertyID'],
            dataPoints
          );
        });
      }
    } else {
      let objVal = object[propDef.Name];

      if (!objVal) {
        objVal = null;
      }

      transformPropertyIntoRow(
        displayProperties,
        objVal,
        propDef,
        object.TypeDefId,
        parentId,
        dataPoints
      );
    }
  });

  return;
};

/**
 * @description Create row for a property
 */
export const transformPropertyIntoRow = (
  displayProperties,
  object,
  propDef,
  typeDefId,
  parentId,
  dataPoints
) => {
  if (propDef.TypeKind === 'Primitive') {
    transformPrimitive(
      displayProperties,
      object,
      propDef,
      typeDefId,
      parentId,
      dataPoints
    );
  }
  if (propDef.TypeKind === 'Enum') {
    transformEnum(
      displayProperties,
      object,
      propDef,
      typeDefId,
      parentId,
      dataPoints
    );
  } else if (propDef.TypeKind === 'Entity') {
    transformEntity(
      displayProperties,
      object,
      propDef,
      typeDefId,
      parentId,
      dataPoints
    );
  }
};

/**
 * @description Create row for a primitive property
 */
export const transformPrimitive = (
  displayProperties,
  object,
  propDef,
  typeDefId,
  parentId,
  dataPoints
) => {
  const isImportant = checkIsImportant(propDef.Name, typeDefId);
  const propertyType = propDef.PrimitiveDataType;
  const propertyDisplay = propDef.Caption;

  const row = createDisplayProperty(
    displayProperties.length + 1,
    parentId,
    propertyDisplay,
    object,
    false,
    isImportant,
    propertyType,
    propDef,
    null,
    dataPoints
  );
  displayProperties.push(row);
};

/**
 * @description Create row for an enum property
 */
export const transformEnum = (
  displayProperties,
  object,
  propDef,
  typeDefId,
  parentId,
  dataPoints
) => {
  const isImportant = checkIsImportant(propDef.Name, typeDefId);
  const propertyType = propDef.TypeKind;
  const propertyDisplay = propDef.Caption;

  const row = createDisplayProperty(
    displayProperties.length + 1,
    parentId,
    propertyDisplay,
    object,
    false,
    isImportant,
    propertyType,
    propDef,
    null,
    dataPoints
  );
  displayProperties.push(row);
};

/**
 * @description Create rows for an entity property
 */
export const transformEntity = (
  displayProperties: any,
  object: any,
  propDef: any,
  typeDefId: string,
  parentId: string,
  dataPoints: any
) => {
  if (!object) {
    if (
      propDef.PropTypeDef.Name.indexOf('IT_') === 0 &&
      propDef.PropTypeDef.Category.InternalName === 'Inheritance'
    ) {
      // leave null for perspective class props
    } else {
      // this entity object is null, could be a new instance being added to a collection by a user, or it could be a null instance from enweb
      object = { ID: null, TypeDefId: propDef.PropTypeDefId };
    }
  }

  const isImportant = checkIsImportant(propDef.Name, typeDefId);
  const displayName = getTypeDefDisplayPropValue(object);
  const instanceName = propDef.PropTypeDef.Caption;

  let nodeDisplayName = '';
  if (propDef.IsCollection) {
    const displayName = getTypeDefDisplayPropValue(object);
    const instanceName = propDef.PropTypeDef.Caption;
    nodeDisplayName = !displayName
      ? `${instanceName}`
      : `${instanceName}: ${displayName}`;
  } else {
    nodeDisplayName = propDef.Caption;
  }

  if (
    propDef.PropTypeDef.Name.indexOf('IT_') === 0 &&
    propDef.PropTypeDef.Category.InternalName === 'Inheritance'
  ) {
    // this prop is a Perspective Prop
    let row = createDisplayProperty(
      displayProperties.length + 1,
      parentId,
      propDef.Caption,
      object,
      false,
      isImportant,
      'perspective',
      propDef,
      null,
      dataPoints
    );
    displayProperties.push(row);
  } else {
    // create a group for the child properties to hang off
    const data = { ID: object.ID, TypeDefId: object.TypeDefId };
    const row = createDisplayProperty(
      displayProperties.length + 1,
      parentId,
      nodeDisplayName,
      '',
      false,
      isImportant,
      'object',
      propDef,
      data,
      dataPoints
    );
    displayProperties.push(row);

    // recursively add child properties
    transformPropertiesOfObject(displayProperties, object, row['propertyID']);
  }
};

/*
 * For a given object and propdef as a starting point, recursively find all datapoints in the object structure
 * isObjectInCollection: this bool indicates whether the passed in object is an item in a collection, meaning that the propDef applies to the parent
 *                       collection of this object and the object needs to be dealt with directly rather than accessed via the propDef
 */
export const rollupDataPoints = (
  object: any,
  propDef: any,
  isObjectInCollection: boolean
) => {
  let dataPoints = [];

  // case for primitives, enums and perspective class props
  if (
    propDef.TypeKind === 'Primitive' ||
    propDef.TypeKind === 'Enum' ||
    (propDef.PropTypeDef &&
      propDef.PropTypeDef.Name.indexOf('IT_') === 0 &&
      propDef.PropTypeDef.Category.InternalName === 'Inheritance')
  ) {
    if (
      object.DataPointLocations &&
      object.DataPointLocations.value &&
      object.DataPointLocations.value.length > 0
    ) {
      dataPoints = object.DataPointLocations.value.filter(
        (item) => item.PropDefId === propDef.ID
      );
    }
  }
  // case for custom type props
  else if (propDef.TypeKind === 'Entity') {
    let childObjects = [];

    if (isObjectInCollection) {
      childObjects.push(object);
    } else if (propDef.IsCollection) {
      let childCollection = object[propDef.Name];
      if (
        childCollection &&
        childCollection.value &&
        childCollection.value.length > 0
      )
        childObjects = childCollection.value;
    } else {
      childObjects.push(object[propDef.Name]);
    }

    for (let i1 = 0; i1 < childObjects.length; i1++) {
      let childObject = childObjects[i1];
      if (!childObject) {
        continue;
      }

      let editableProps = editablePropsMapping[childObject.TypeDefId];

      for (let i2 = 0; i2 < editableProps.length; i2++) {
        let childPropDef = editableProps[i2];
        let childDataPoints = rollupDataPoints(
          childObject,
          childPropDef,
          false
        );

        for (let i3 = 0; i3 < childDataPoints.length; i3++) {
          let childDataPoint = childDataPoints[i3];
          dataPoints.push(childDataPoint);
        }
      }
    }
  }

  return dataPoints;
};

// Gets the value of the display property value for the given typedef object
export const getTypeDefDisplayPropValue = (object) => {
  if (object === null) {
    return null;
  }
  let displayName;
  const typeDefId = object['TypeDefId'];
  const displayPropsForTypeDef = displayPropsMapping[typeDefId];

  if (displayPropsForTypeDef != null) {
    const displayProp = displayPropsForTypeDef[0];
    if (displayProp != null) {
      const identifier = displayProp['Name'];
      displayName = object[identifier];
    }
  }
  return displayName;
};

/// Checks whether the given property is marked as important in the specified typedef
export const checkIsImportant = (propertyName, typeDefId) => {
  const propGroupsForTypeDef = propGroupsMapping[typeDefId];
  let important = false;
  if (propGroupsForTypeDef != null) {
    const importantPropGroup = propGroupsForTypeDef.find(
      (g) => g.Purpose === 'IsImportant'
    );
    if (importantPropGroup && importantPropGroup?.PropLinks) {
      const importantProperties = importantPropGroup.PropLinks.value;
      if (importantProperties != null) {
        const prop = importantProperties.find(
          (p) => p.PropDef.Name === propertyName
        );
        important = prop != null;
      }
    }
  }

  return important;
};

/// Creates a property for display
export const createDisplayProperty = (
  id,
  pid,
  name: string,
  value,
  isCollection,
  isImportant,
  propertyType,
  propDef,
  data,
  dataPoints
) => {
  let newProperty = {};
  let incomplete =
    (value === null || value === undefined || value === '') && isImportant
      ? true
      : false;
  //set all the collection node and parent level node incomplete status to false, a recalculation process Would be involved once the grid data loaded
  if (isCollection || propertyType === 'object') {
    incomplete = false;
  }

  let perspectiveClass = null;
  if (propertyType === 'perspective') {
    if (value !== null) {
      perspectiveClass = {
        ...value,
        _ImageId: propDef?.PropTypeDef?._ImageId,
      };
    }
  }

  let highlights = [];
  if (dataPoints && dataPoints.length > 0) {
    dataPoints.forEach((dataPoint, i) => {
      let geometry = JSON.parse(dataPoint.Geometry);
      highlights.push({
        text: geometry.text,
        start: geometry.start,
        end: geometry.end,
      });
    });
  }

  let highlightsCaption = '';
  if (highlights.length === 1) {
    highlightsCaption = highlights[0].text;
  } else if (highlights.length > 1) {
    highlightsCaption = highlights.length + ' Highlights';
  }

  newProperty['propertyName'] = name;
  newProperty['value'] =
    propertyType === 'perspective' ? perspectiveClass : value;
  newProperty['highlights'] = highlightsCaption;
  newProperty['highlightData'] = highlights;
  newProperty['data'] = data;
  newProperty['propertyID'] = id;
  newProperty['isImportant'] = isImportant;
  newProperty['isCollection'] = isCollection;
  newProperty['incomplete'] = incomplete;
  newProperty['Items'] = [];
  newProperty['propDef'] = propDef;

  if (pid != 0) {
    newProperty['pId'] = pid;
  }
  return newProperty;
};

/// Transforms the sync fusion data tree to JSON object
export const transformSyncFusionPropertiesToJson = (
  dataSource,
  businessObject
) => {
  // convert the sync fusion grid rows to a tree representation
  const dataTree = createTree(dataSource);

  const treeObj = {
    ID: businessObject.ID,
    TypeDefId: businessObject.TypeDefId,
  };

  // transform this tree to represent the server object
  dataTree?.forEach((data) => {
    transformSyncFusionPropertyNode(data, treeObj);
  });

  return treeObj;
};

// Transforms the specified sync fusion property node to an object
// that can be send to the server for update.
export const transformSyncFusionPropertyNode = (propertyNode, object) => {
  // get the property name for this node
  const propName = propertyNode.propDef.Name;

  // Check if this node is a collection node
  if (propertyNode.propDef.IsCollection) {
    const objCollection = [];

    // get any child nodes for this node
    const childNodes = propertyNode?.Items;

    // if there are any child nodes for this node, get all the child nodes and tranform them
    if (childNodes?.length > 0) {
      // transform all the child nodes
      childNodes?.forEach((childNode) => {
        const childObj = { ...childNode.data };

        if (!childObj?.ID) {
          childObj['@enweb.Save'] = true;
        }

        const childNodesToDelete = propertyNode?.childrenToDelete ?? childNode?.childrenToDelete;

        if (childNodesToDelete?.length > 0) {
           // track all the childNode that has been deleted from UI
           childNodesToDelete?.forEach((item) => {
            item['@enweb.Save'] = true
            item['@enweb.Delete'] = true;

            const hasRecord = objCollection.find((x) => x?.ID === item?.ID);

            // push the item if there's no record.
            if (!hasRecord) objCollection.push(item);
           });
        }

        // childNode is the dummy layer row, its children are the properties of the object
        const propNodes = childNode?.Items;

        if (propNodes.length > 0 && !childNodesToDelete?.length) {
          propNodes?.forEach((propNode) => {
            transformSyncFusionPropertyNode(propNode, childObj);
          });
        }

        // push child object if the item is not deleted.
        if (!childNodesToDelete?.length) {
          objCollection.push(childObj);
        }
      });
    }

    object[propName] = objCollection;
  } else {
    populateObject(propertyNode, object);
  }
};

/**
 * @description Populate an object from row data
 */
export const populateObject = (propertyNode, object) => {
  const propName = propertyNode.propDef.Name;
  
  if (propertyNode.propDef.TypeKind === 'Primitive') {
    const propValue = propertyNode['value'] ?? null;

    if (propertyNode.propDef.PrimitiveDataType === 'Boolean')
      object[propName] = propValue === undefined || propValue === null ? null : propValue;
    else object[propName] = propValue;
  } else if (propertyNode.propDef.TypeKind === 'Enum') {
    const propValue = propertyNode['value'];
    object[propName] = !propValue ? null : propValue;
  } else if (propertyNode.propDef.TypeKind === 'Entity') {
    if (
      propertyNode.propDef.PropTypeDef.Name.indexOf('IT_') === 0 &&
      propertyNode.propDef.PropTypeDef.Category.InternalName === 'Inheritance'
    ) {
      if (propertyNode.value && propertyNode.value.ID) {
        object[`${propName}Id`] = propertyNode.value.ID;
        object[propName] = {
          ID: propertyNode.value.ID,
          TypeDefId: propertyNode.value.TypeDefId,
          '@enweb.Save': true,
        };
      } else {
        object[propName] = null;
      }

      return;
    }

    let childObj = { ...propertyNode.data };
    if (!childObj) {
      childObj = {};
    }

    if (!childObj?.ID) {
      childObj['@enweb.Save'] = true;
    }

    // propertyNode is the dummy layer row, its children are the properties of the object
    const propNodes = propertyNode?.Items;

    if (propNodes?.length > 0) {
      propNodes.forEach((propNode) => {
        transformSyncFusionPropertyNode(propNode, childObj);
      });
    }

    object[propName] = childObj;
  }
};
